// Setup cart variables
var lsCart, cart, lastCart = [];
if(localStorage) {
	lsCart = true;
	// If no cart, setup one.
	if (localStorage.getItem("cart") === null) {
		cart = {};
		cart.products = {};
		localStorage.setItem('cart', JSON.stringify(cart));

	// If cart exists, get it.
	} else {
		cart = JSON.parse(localStorage.getItem('cart'));
	}
} else {
	lsCart = false;
}

// Working Map script from H&F
// Require API details
//
// var features, mapDefaults, gMap;
// var gMapReady = function() {
// 	$mapContainer.addClass('ready');
// };
// var gMapInfoBox = function(marker, infobox, i) {
// 	if(mapDefaults.pins[i].link !== "") {
// 		google.maps.event.addListener(marker, 'click', (function(marker, i) {
// 			return function() {
// 				infobox.setContent('<div class="infoBox"><h2 class="infoBox__heading">'+mapDefaults.pins[i].name+'</h2><div class="infoBox__body">View or get full directions to '+mapDefaults.pins[i].name+'<br />on <a href="'+mapDefaults.pins[i].link+'" target="_blank">Google Maps</a></div></div>');
// 				infobox.open(gMap, marker);
// 			};
// 		})(marker, i));
// 	}
// };
// HF.gMapEmbed = function() {
// 	var mapContainer = $mapContainer[0],
// 		mapOptions = {
// 			backgroundColor: '#ffffff',
// 			zoom: +mapDefaults.zoom,
// 			center: new google.maps.LatLng(+mapDefaults.center.lat, +mapDefaults.center.lng),
// 			disableDefaultUI: false,
// 			panControl: false,
// 			mapTypeControl: false,
// 			scrollwheel: false,
// 			mapTypeId: 'no_POI_map'
// 		},
// 		NoPOIMap = 'no_POI_map',
// 		styles = [{ stylers: [{ "saturation": -100 }]}],
// 		customMapType = new google.maps.StyledMapType(styles),
// 		infobox = new google.maps.InfoWindow();

// 	gMap = new google.maps.Map(mapContainer, mapOptions);
// 	gMap.mapTypes.set(NoPOIMap, customMapType);
// 	google.maps.event.addListenerOnce(gMap,'tilesloaded',gMapReady);
// 	google.maps.event.addDomListener(window, 'resize', function() {
// 		gMap.setCenter(mapOptions.center);
// 	});

// 	for (var i = 0; i < mapDefaults.pins.length; i++) {
// 		var marker = new google.maps.Marker({
// 			draggable: false,
// 			position: new google.maps.LatLng(+mapDefaults.pins[i].lat, +mapDefaults.pins[i].lng),
// 			icon: mapDefaults.pins[i].pin,
// 			map: gMap
// 		});
// 		gMapInfoBox(marker, infobox, i);
// 	}
// };
// var gMapScripts = function() {
// 	if($('[data-map-settings]').length) {
// 		features = $('[data-map-settings]').data('map-settings')[0];
// 		if(typeof google === 'undefined') {
// 			$mapContainer = $('.gmap');
// 			mapDefaults = features.map;
// 			$.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyCGXpabVMymJzHEIv2LIVIgoxnYOlU3TWc&async=2&callback=HF.gMapEmbed");
// 		} else if(typeof features.map !== "undefined" && typeof google !== 'undefined') {
// 			$mapContainer = $('.gmap');
// 			HF.gMapEmbed();
// 		}
// 	}
// };
// gMapScripts();

// NON-Working Map script
//
// function embedMap() {
// 	var $mapContainer = $('.contact__map-box');
// 	var mapContainer = $mapContainer[0];
// 	var latlng = new google.maps.LatLng(51.548394, -0.537057);
// 	var NoPOIMap = 'no_POI_map';
// 	var mapOptions = {
// 		backgroundColor: 'transparent',
// 		zoom: 12,
// 		center: latlng,
// 		disableDefaultUI: false,
// 		panControl: false,
// 		mapTypeControl: false,
// 		draggable: (($(document).width() >= 560) ? true : false),
// 		scrollwheel: false,
// 		mapTypeId: 'no_POI_map'
// 	};
// 	var map = new google.maps.Map(mapContainer, mapOptions);

// 	var styles = [{
// 		stylers: [
// 			{ "saturation": -100 }
// 		]
// 	}];
// 	var customMapType = new google.maps.StyledMapType(styles);

// 	map.mapTypes.set(NoPOIMap, customMapType);

// 	google.maps.event.addDomListener(window, "resize", function() {
// 		var center = map.getCenter();
// 		google.maps.event.trigger(map, "resize");
// 		map.setCenter(center);
// 		if($(document).width() >= 560) {
// 			map.draggable = true;
// 		} else {
// 			map.draggable = false;
// 		}
// 	});

// 	var pin = {
// 		path: 'M15.63,0A15.63,15.63,0,0,0,1.53,22.38L15.63,50,29.55,22.72A15.63,15.63,0,0,0,15.63,0Zm0,25A9.38,9.38,0,1,1,25,15.63,9.38,9.38,0,0,1,15.63,25Z',
// 		scale: 1,
// 		strokeColor: '#3b7d3c',
// 		strokeWeight: 2,
// 		anchor: new google.maps.Point(15.625, 50),
// 	};

// 	var marker = new google.maps.Marker({
// 		map: map,
// 		draggable: false,
// 		animation: google.maps.Animation.DROP,
// 		position: latlng,
// 		icon: pin
// 	});

// 	marker.setMap(map);
// }

var lightBox;
function loadGalleries() {
	$('.pswimg').each( function() {
		var $pic = $(this),

		getItems = function() {
			var items = [];
			$pic.find('.image-carousel__image-link').each(function() {
				var $a       = $(this),
				 	$href    = $a.attr('href'),
					$size    = $a.data('size').split('x'),
					$width   = $size[0],
					$height  = $size[1],
					$caption = $a.data('caption');

				var item = {
					src  : $href,
					w    : $width,
					h    : $height,
					title: $caption,
				};

				items.push(item);
			});
			return items;
		};

		var items = getItems();

		var $pswp = $('.pswp')[0];
		$pic.on('click', '.image-carousel__image-link', function(e) {
			e.preventDefault();

			var bS = 0;
			if($(window).width() >= 560) {
				bS = 65;
			}

			var $index = $(this).index();
			var options = {
				index: $index,
				bgOpacity: 1,
				showHideOpacity: true,
				barsSize: {top:bS, bottom:bS},
				fullscreenEl: true,
				zoomEl: true,
				shareEl: false,
				arrowEl: true
			};

			// Initialize PhotoSwipe
			lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
			lightBox.init();
		});
	});
}

function updateCartIcon() {
	var count = 0;
	var i;
	var $text = $('.liveCount');
	var $data = $('.dataCount');
	for (i in cart.products) {
		if (cart.products.hasOwnProperty(i)) {
			count++;
		}
	}
	$text.text(count);
	$data.data('count', count);

	if(count > 0) {
		$data.removeClass('empty');
	} else {
		$data.addClass('empty').removeClass('open');
	}

	return count;
}
function updateCart(firstRun) {
	firstRun = (typeof firstRun !== 'undefined') ?  firstRun : false;
	var $root = $('.eq-list-loading'),
		url = $('body').data('equrl');

	var ids = [];
	$.each(cart.products, function(index){
		if(ids.indexOf(index) === -1){
			ids.push(index);
		}
	});
	var count = updateCartIcon();

	var params = "emptied";
	if(count > 0) {
		params = ids.join(',');
	}
	if($root.hasClass('submitted')) {
		params = "submitted";
		cart.products = {};
		localStorage.setItem('cart', JSON.stringify(cart));
		updateCartIcon();
	}
	$.ajax({
		url: url+'?ids='+params,
		type: "get",
		dataType: "html",
		success: function(data) {
			var $outer = $(data).find('.products');
			var $inner = $(data).find('.products__wrapper');
			$root.html($outer);
			$('.eq-overlay__products').html($inner);
		}
	});


	var removed = $.grep(lastCart, function(el) {
		return $.inArray( el, ids ) === -1;
	});

	if(!$.isEmptyObject(removed)) {
		$("[data-pid='"+removed+"']").removeClass('remove').addClass('add');
	}

	lastCart = ids;
}
function addToCart(id, product) {
	cart.products[id] = product;
	localStorage.setItem('cart', JSON.stringify(cart));
	updateCartIcon();
	updateCart();
}
function removeFromCart(id) {
	delete cart.products[id];
	localStorage.setItem('cart', JSON.stringify(cart));
	updateCartIcon();
	updateCart();
}
function checkCartStatus() {
	$.each(cart.products, function(index) {
		if($('#p'+index).length) {
			$('#p'+index).find('button').addClass('remove').removeClass('add');
		}
	});
}

// lazySizes.js config
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.expand = 0;

$(document).ready(function() {
	fluidvids.init();
	loadGalleries();

	// Flickity
	var options = {
		arrowShape: 'M0,50L35.35,14.65,38.72,18,9.11,47.62H100v4.76H9.11L38.72,82l-3.37,3.37Z',
		imagesLoaded: true
	};
	if ( matchMedia('screen and (min-width: 800px)').matches ) {
		options.arrowShape = 'M49.95,100L0,50,49.95,0l2.82,2.82L7.59,48H100v4H7.66L52.76,97.18Z';
	}

	$('.video-carousel').flickity( options );
	$('.image-carousel').flickity( options );
	$('.twitter-carousel').flickity( options );

	options.autoPlay = true;

	$('.equipment-carousel').flickity( options );

	// // Setup map
	// if($('.contact__map-box').length) {
	// 	embedMap();
	// }

	// Clicks
	$('.header__hamburger').on('click', function() {
		$('.mobile-nav').addClass('mobile-nav--visible');
	});

	$('.mobile-nav__hamburger').on('click', function() {
		$('.mobile-nav').removeClass('mobile-nav--visible');
	});

	$('.mobile-nav a[href^="#"], .quicknav a[href^="#"]').on('click', function(event) {
		event.preventDefault();
		
		let el = $(event.target.hash)[0];

		el.openCloseSection(event, true);

    $('.mobile-nav').removeClass('mobile-nav--visible');
	});

	$('.header__search').on('click', function() {
		$('.search').addClass('search--visible');
		$('.search__field').focus();
	});

	$('.search__close').on('click', function() {
		$('.search').removeClass('search--visible');
	});

	$('.faqs__article-title').on('click', function() {
		var $art = $(this).parent('article');
		$art.toggleClass('faqs__article--open');
	});

	$('.signup-form').on('click', function(e) {
		e.preventDefault();
		$('.footer-desktop').toggleClass('footer-desktop--show-subscribe');
		$('.subscribe__field').focus();
	});

	$('.container').on('click', '.product-detail__add', function() {
		var $this = $(this).find('button');
		var product = {};
		var id = $this.attr('data-pid');

		if($this.hasClass('add')) {
			product = {};
			product.name = $this.attr('data-pname');
			product.url = $this.attr('data-purl');
			addToCart(id, product);

			var $el = $('.header__el');
			if($el.hasClass('header__el--pop')||$el.hasClass('header__el--pop-pop')) {
				$el.toggleClass('header__el--pop').toggleClass('header__el--pop-pop');
			} else {
				$el.addClass('header__el--pop');
			}
			$this.removeClass('add').addClass('remove');
		} else if($this.hasClass('remove')) {
			removeFromCart(id);
			$this.removeClass('remove').addClass('add');
		}
	});

	$('.container').on('click', '.products__add.add', function(e) {
		var $this = $(this);
		var product = {};
		var id = $this.attr('data-pid');
		product = {};
		product.name = $this.attr('data-pname');
		product.url = $this.attr('data-purl');
		addToCart(id, product);

		var $el = $('.header__el');
		if($el.hasClass('header__el--pop')||$el.hasClass('header__el--pop-pop')) {
			$el.toggleClass('header__el--pop').toggleClass('header__el--pop-pop');
		} else {
			$el.addClass('header__el--pop');
		}
		$this.removeClass('add').addClass('remove');
		e.preventDefault();
		e.stopPropogation();
	});

	$('.container').on('click', '.products__add.remove', function(e) {
		var $this = $(this);
		var id = $this.attr('data-pid');
		removeFromCart(id);
		$this.removeClass('remove').addClass('add');
		e.preventDefault();
		e.stopPropogation();
	});

	$('.container').on('click', '.products__remove-all', function() {
		cart.products = {};
		localStorage.setItem('cart', JSON.stringify(cart));
		updateCartIcon();
		updateCart();
	});

	$('.eq-overlay__tab').on('click', function() {
		$('.eq-overlay').toggleClass('open');
	});

	$('.header__el').on('click', function(e) {
		var $this = $(this);
		if($(this).data('count') <= 0) {
			e.preventDefault();
			if($this.hasClass('header__el--pop')) {
				$this.toggleClass('header__el--pop').toggleClass('header__el--pop-pop');
			} else {
				$this.toggleClass('header__el--pop');
			}
		}
	});

	$(document).on('click', function(event) {
		if (!$(event.target).closest('.eq-overlay').length) {
			$('.eq-overlay').removeClass('open');
		}
	});

	$('.ajax-subscribe').submit(function (e) {
		e.preventDefault();
		var $this = $(this);
		var $msg = $this.find('.ajax-message');
		$.getJSON(
		this.action + "?callback=?",
		$this.serialize(),
		function (data) {
			if (data.Status === 400) {
				alert("Error: " + data.Message);
			} else { // 20
				$msg.text($msg.data('success'));
				$this.addClass('ajax-subscribe-success');
			}
		});
	});

	$(".product-fiter-group__form").on("change", "input:checkbox", function(){
		var $this = $(this);
		if($this.is(':checked')) {
			var $group = $this.closest('ul');
			$group.find('input').not(this).attr('checked', false);
		}
        $(".product-fiter-group__form").submit();
    });
});

$(window).on('load', function() {
	updateCartIcon();
	updateCart(true);
	checkCartStatus();
});